import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver'

import EditEvent from './EditEvent.js';
import AddEvent from './AddEvent.js';
import UploadEvent from './UploadEvent.js';
import EditUploadedEvent from './EditUploadedEvent.js';
import Confirmation from '../../components/Confirmation.js';
import Loading from '../../components/Loading.js';
import InfoPopup from '../../components/InfoPopup.js';

import '../../styles/admin/Events.css';

let VISIBLE_FIELDS = ['eventName', 'eventDate', 'accessClose', 'facilitator', 'participantCount', 'eventType', 'sessionAccess'];
let VISIBLE_UPLOAD_FIELDS = ['eventName', 'eventDate', 'accessClose', 'facilitator'];

const API_ROUTE = process.env.REACT_APP_API_URL;

function Events() {
  const [events, setEvents] = useState([]);
  const [visibleEvents, setVisibleEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [eventIndex, setEventIndex] = useState(-1);
  const [addingEvent, setAddingEvent] = useState(false);
  const [uploadedEvents, setUploadedEvents] = useState([]);
  const [visibleUploadedEvents, setVisibleUploadedEvents] = useState([]);
  const [uploadedEventIndex, setUploadedEventIndex] = useState(-1);
  const [uploading, setUploading] = useState(false);
  const [uploadedDeleting, setUploadedDeleting] = useState(-1);
  const [infoPopup, setInfoPopup] = useState(null);
  const [loading, setLoading] = useState(false);

  const valToString = (val) => {
    if (val instanceof Date) {
      return val.toDateString();
    } else if (val instanceof Object) {
      return `${val.forename} ${val.surname}`;
    } else {
      const newVal = val.toString();
      if (newVal.includes('Non-reg')) {
        return 'Discount';
      } else {
        return newVal;
      }
    }
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const editEvent = (val) => {
    setEventIndex(val);
  };

  const closeEvent = () => {
    setEventIndex(-1);
  };

  const addEvent = () => {
    setAddingEvent(true);
  };

  const closeAddEvent = () => {
    setAddingEvent(false);
  };

  const closeUploadedEvent = (index) => {
    setUploadedDeleting(index);
  };

  const cancelCloseUploadedEvent = () => {
    setUploadedDeleting(-1);
  };

  const uploadEvent = () => {
    setUploading(true);
  };

  const cancelUploadEvent = () => {
    setUploading(false);
  };

  const editUploadedEvent = (index) => {
    setUploadedEventIndex(index);
  };

  const closeEditUploadedEvent = () => {
    setUploadedEventIndex(-1);
  };

  const downloadFiles = (event, uploaded) => {
    setLoading(true);
    axios.get(`${API_ROUTE}/event/event_file`, {
      withCredentials: true,
      params: new URLSearchParams({eid: event.eid, uploaded: uploaded}),
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'arraybuffer'
    }).then((res) => {
      const zip = new Blob([res.data], { type: 'application/zip' });

      const contentDisposition = res.headers.get('Content-Disposition');
      const filename = contentDisposition.split('filename=')[1].replace(/\"/g, '');
      saveAs(zip, filename);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
      setLoading(false);
    });
  };

  const deleteUploadedEvent = () => {
    const curEventData = {
      eid: uploadedEvents[uploadedDeleting].eid,
      uploaded: true
    };

    axios.delete(`${API_ROUTE}/event/delete`, {
      data: curEventData,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const newInfoPopup = <InfoPopup message={'Event Deleted Successfully'}
                                      success={true} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
      // alert('Event deleted successfully');
      cancelCloseUploadedEvent();
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  };

  const uploadEventFile = (eventFile) => {
    const eventPost = {
      eventFile: eventFile
    };
    axios.post(`${API_ROUTE}/event/upload_event`, eventPost, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res);
      setUploading(false);
      const newInfoPopup = <InfoPopup message={res.data.message}
                                      success={res.data.success} />;
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
      // alert(err.response.data.message);
    });
  };

  useEffect(() => {
    let newVisibleEvents = [];
    for (let e of events) {
      let cur = {};
      for (let key of Object.keys(e)) {
        if (VISIBLE_FIELDS.includes(key)) {
          if (e[key] instanceof Date) {
            let newDate = e[key];
            newDate.setTime(newDate.getTime() + (8 * 60*60*1000));
            cur[key] = newDate;
          } else {
            cur[key] = e[key];
          }
        }
        if (key == 'participants') {
          cur['participantCount'] = e.participants.length;
          if (e.mwParticipants != null) {
            cur['participantCount'] = `${cur['participantCount']} (${e.mwParticipants})`;
          }
        }
      }
      newVisibleEvents.push(cur);
    }
    setVisibleEvents(newVisibleEvents);
  }, [events]);

  useEffect(() => {
    let newVisibleUploadedEvents = [];
    for (let e of uploadedEvents) {
      let cur = {};
      for (let key of Object.keys(e)) {
        if (VISIBLE_UPLOAD_FIELDS.includes(key)) {
          cur[key] = e[key];
        }
      }
      newVisibleUploadedEvents.push(cur);
    }
    setVisibleUploadedEvents(newVisibleUploadedEvents);
  }, [uploadedEvents]);

  useEffect(() => {
    let newVisibleEvents = [];
    for (let e of events) {
      if (!e.eventName.toLowerCase().includes(searchQuery.toLowerCase())) {
        continue;
      }
      let cur = {};
      for (let key of VISIBLE_FIELDS) {
        cur[key] = e[key];
      }
      newVisibleEvents.push(cur);
    }
    setVisibleEvents(newVisibleEvents);
  }, [searchQuery]);

  useEffect(() => {
    axios.get(`${API_ROUTE}/event/all`, {
      withCredentials: true
    }).then((res) => {
      console.log(res);
      let events = [];
      for (let e of res.data.events) {
        let cur = e;
        cur.eventDate = new Date(cur.eventDate);
        cur.accessClose = new Date(cur.accessClose);
        events.push(cur);
      }

      setEvents(events);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  }, [addingEvent, eventIndex]);

  useEffect(() => {
    axios.get(`${API_ROUTE}/event/uploaded_events`, {
      withCredentials: true
    }).then((res) => {
      let newUploadedEvents = [];
      for (let e of res.data.events) {
        let cur = e;
        cur.eventDate = new Date(cur.eventDate);
        cur.accessClose = new Date(cur.accessClose);
        newUploadedEvents.push(cur);
      }

      setUploadedEvents(newUploadedEvents);
    }).catch((err) => {
      console.log(err);
      // if (Object.keys(err).includes('response')) {
      //   const newInfoPopup = <InfoPopup message={err.response.message}
      //                                   success={false} />;
      //   setInfoPopup(newInfoPopup);
      //   setTimeout(() => {
      //     setInfoPopup(null);
      //   }, process.env.REACT_APP_INFO_TIMEOUT);
      //   return;
      // }
      const newInfoPopup = <InfoPopup message={err.response.data.message}
                                      success={err.response.data.success} />
      setInfoPopup(newInfoPopup);
      setTimeout(() => {
        setInfoPopup(null);
      }, process.env.REACT_APP_INFO_TIMEOUT);
    });
  }, [uploading, uploadedDeleting, uploadedEventIndex]);

  return (
    <>
      <div>
        {infoPopup && (infoPopup)}
        {eventIndex >= 0 && (
          <EditEvent  cbClose={(e) => closeEvent()}
                      currentEvent={events[eventIndex]}/>
        )}
        {addingEvent && (
          <AddEvent cbClose={(e) => closeAddEvent()} />
        )}
        {uploading && (
          <UploadEvent  cbUpload={uploadEventFile}
                        cbCancel={cancelUploadEvent} />
        )}
        {uploadedDeleting >= 0 && (
          <Confirmation cbDelete={deleteUploadedEvent}
                        cbCancel={cancelCloseUploadedEvent}
                        message={`Please ensure you have downloaded the event files before deletion. This action will remove the event and all participants from the system. It cannot be undone, however, if you have downloaded the event files you will be able to upload the event for editing.`}
                        buttonName={"Delete"} />
        )}
        {uploadedEventIndex >= 0 && (
          <EditUploadedEvent  cbClose={closeEditUploadedEvent}
                              currentEvent={uploadedEvents[uploadedEventIndex]} />
        )}
        {loading && (
          <Loading />
        )}
      </div>
      <div className="container-superadminevents">
        <div className="container-topbar">
          <div className="container-search">
            <div className="">Search: </div>
            <input  id="input-search"
                    className="input-search"
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder="Search for an event" />
          </div>
          <div>
            <button onClick={() => addEvent()}>Add Event</button>
            <button onClick={() => uploadEvent()}>Upload Event</button>
          </div>
        </div>
        <div className="container-eventlist">
          <div className="grid-item grid-title">
            <div>Name</div>
          </div>
          <div className="grid-item grid-title">
            <div>Event Date</div>
          </div>
          <div className="grid-item grid-title">
            <div>Access Close</div>
          </div>
          <div className="grid-item grid-title">
            <div>Facilitator</div>
          </div>
          <div className="grid-item grid-title">
            <div>Participants</div>
          </div>
          <div className="grid-item grid-title">
            <div>Event Rate</div>
          </div>
          <div className="grid-item grid-title">
            <div>Session Access</div>
          </div>
          <div className="grid-item grid-title">
          </div>

          {events.length == 0 && (
            <>
              <div className="grid-row"><br /></div>
              <div className="grid-row">
                No events found. Try adding one with the 'Add Event' button or uploading with the 'Upload Event' button.
              </div>
            </>
          )}
          {visibleEvents.map((eventData, i) =>
            <>
              {VISIBLE_FIELDS.map((key, i) =>
                <div className="grid-item">
                  <div className="grid-text">{valToString(eventData[key])}</div>
                </div>
              )}
              <div className="grid-item">
                <button onClick={() => editEvent(i)}>Dashboard</button>
              </div>
            </>
          )}
        </div>
        {uploadedEvents.length > 0 && (
          <>
            <div className="title">Uploaded Events</div>
            <div className="container-uploadedlist">
              <div className="grid-item grid-title">
                <div>Name</div>
              </div>
              <div className="grid-item grid-title">
                <div>Event Date</div>
              </div>
              <div className="grid-item grid-title">
                <div>Access Close</div>
              </div>
              <div className="grid-item grid-title">
                <div>Facilitator</div>
              </div>
              <div className="grid-item grid-title">
              </div>
              <div className="grid-item grid-title">
              </div>
              <div className="grid-item grid-title">
              </div>

              {visibleUploadedEvents.map((eventData, i) =>
                <>
                  {Object.keys(eventData).map((key, i) =>
                    <div className="grid-item">
                      <div className="grid-text">{valToString(eventData[key])}</div>
                    </div>
                  )}
                  <div className="grid-item">
                    <button onClick={() => editUploadedEvent(i)}>View Event</button>
                  </div>
                  <div className="grid-item">
                    <button onClick={() => downloadFiles(uploadedEvents[i], true)}>Download Files</button>
                  </div>
                  <div className="grid-item">
                    <button onClick={() => closeUploadedEvent(i)}>Remove Upload</button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Events;
